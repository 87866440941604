import React, { useCallback } from "react";
import { Trans } from "@lingui/macro";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useApolloClient } from "@apollo/client";

import { setTestId } from "test-utils/set-testid";
import { useKeycloak } from "@react-keycloak/web";
import { clientCookies } from "shared/keycloak";

function SignOutButton({ minimal }) {
  const client = useApolloClient();
  const { keycloak } = useKeycloak();

  const signOut = useCallback(async () => {
    keycloak.logout();
    clientCookies.clear();
    client.writeData({ data: { isLoggedIn: false, userRole: null } });
  }, [keycloak, client]);

  return (
    <div>
      <Button
        icon={IconNames.LOG_OUT}
        text={<Trans id="Sign Out" />}
        minimal={minimal}
        elementRef={setTestId`sign-out-button`}
        onClick={() => signOut().then(setTimeout(client.resetStore, 100))}
      />
    </div>
  );
}

export default React.memo(SignOutButton);
