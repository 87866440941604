import React from "react";

import { ErrorMessage } from "../components";
import { Trans } from "@lingui/macro";
import { useKeycloak } from "@react-keycloak/web";
import { verifyUser } from "verify-user";

import { SignOutButton } from "containers";
import { ALLOW_USER_LOGIN } from "constants/envs";

export const CurrentUserProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <Trans>Authenticating...</Trans>;
  }

  const { isLoggedIn, userRole } = verifyUser(keycloak.token);
  const isAdmin = isLoggedIn && userRole === "ADMIN";
  const loginAllowed = isAdmin || (isLoggedIn && ALLOW_USER_LOGIN);

  console.log({
    isLoggedIn,
    ALLOW_USER_LOGIN: ALLOW_USER_LOGIN,
    'isAdmin: isLoggedIn && userRole === "ADMIN"': isAdmin,
    "loginAllowed: isAdmin || (isLoggedIn && ALLOW_USER_LOGIN)": loginAllowed,
    "second rule: isLoggedIn && ALLOW_USER_LOGIN":
      isLoggedIn && ALLOW_USER_LOGIN,
  });

  return (
    <>
      {loginAllowed ? (
        children
      ) : (
        <ErrorMessage
          title={<Trans>Access denied</Trans>}
          description={
            <Trans>Only administrators allowed to use this service</Trans>
          }
          action={<SignOutButton />}
        />
      )}
    </>
  );
};

export default React.memo(CurrentUserProvider);
