import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { H2, Callout, Intent } from "@blueprintjs/core";

import { Breadcrumbs } from "containers";
import { ProcessingList } from "containers";
import { useParams } from "react-router-dom";
import useProjectQuery from "components/project-workflow-list/queries";
import { EditableProjectNameInput } from "components";

import { useProjectProgress } from "hooks/use-project-progress";
import ProjectProgress from "components/project-progress";
import ProjectDetailsDialog from "components/project-card/project-details-dialog";

function Processings() {
  const { projectId } = useParams();

  const [isOpenDetailsProject, setIsOpenDetailsProject] = useState(false);
  const handleClick = () => setIsOpenDetailsProject(!isOpenDetailsProject);

  const { projectProgressResult } = useProjectProgress(projectId);
  const { data, isLoading, isSuccess, isError } = useProjectQuery(projectId);

  return (
    <div className="processings">
      <Breadcrumbs />
      <div className="processings__container">
        <H2>
          <EditableProjectNameInput value={data?.name} />
        </H2>
        <H2>
          <Trans>Processings</Trans>
        </H2>
        <div className="callout">
          <Callout
            className="create-processing-tip"
            intent={Intent.PRIMARY}
            title={<Trans id="create-processing-tip">Tip</Trans>}
          >
            <Trans>
              To to create a processing please go to workflows and select one
            </Trans>
          </Callout>

          {projectProgressResult && (
            <Callout
              className="create-processing-progress"
              intent={Intent.PRIMARY}
              title={<Trans>Status</Trans>}
              icon={null}
            >
              <ProjectProgress
                id={projectId}
                progress={projectProgressResult}
                isProcessings
                handleOpen={handleClick}
              />
            </Callout>
          )}
        </div>
        <ProcessingList />
      </div>

      {isOpenDetailsProject && (
        <ProjectDetailsDialog projectId={projectId} handleClose={handleClick} />
      )}
    </div>
  );
}

export default React.memo(Processings);
