const STYLE_DARK = "dark-v10";
const STYLE_LIGHT = "light-v10";
const STYLE_STREETS = "streets-v11";
const STYLE_OUTDOORS = "outdoors-v11";
const STYLE_SATELLITE = "satellite-v9";

export const StyleNames = {
  DARK: "DARK",
  LIGHT: "LIGHT",
  STREETS: "STREETS",
  OUTDOORS: "OUTDOORS",
  SATELLITE: "SATELLITE",
};

export const Styles = {
  [StyleNames.DARK]: STYLE_DARK,
  [StyleNames.LIGHT]: STYLE_LIGHT,
  [StyleNames.STREETS]: STYLE_STREETS,
  [StyleNames.OUTDOORS]: STYLE_OUTDOORS,
  [StyleNames.SATELLITE]: STYLE_SATELLITE,
};

export const STYLE_BASE_URL = "mapbox://styles/mapbox/";
