export const LayerIds = {
  PROCESSED: "wm-processed-fill-extrusion",
  PROCESSED_OUTLINE: "wm-processed-outline",
};

export const SourceIds = {
  PROCESSED: "wm-processed",
};

export const LayerStyles = {
  getProcessed(id, source) {
    return {
      id: LayerIds.PROCESSED,
      type: "fill-extrusion",
      source,
      "source-layer": "vector_layer",
      minzoom: 14,
      paint: {
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          15,
          ["get", "building_height"],
        ],
        "fill-extrusion-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          1,
          18,
          0.25,
        ],
        "fill-extrusion-color": "#aaa000",
      },
    };
  },
  getProcessedOutline(id, source) {
    return {
      id: LayerIds.PROCESSED_OUTLINE,
      type: "line",
      source,
      "source-layer": "vector_layer",
      paint: {
        "line-color": "white",
        "line-width": ["interpolate", ["linear"], ["zoom"], 14, 0, 16, 2],
        "line-opacity": ["interpolate", ["linear"], ["zoom"], 14, 1, 18, 0.25],
      },
    };
  },
};
