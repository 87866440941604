import React from "react";
import { Trans } from "@lingui/macro";
import { EditableText, H2, H3 } from "@blueprintjs/core";

import LinkWorkflowButton from "components/link-workflow-button";
import { Breadcrumbs } from "containers";
import {
  ProjectWorkflowList,
  StateLoading,
  ErrorMessage,
  EditableProjectNameInput,
} from "components";
import { useParams } from "react-router-dom";
import CreateAndLinkWorkflow from "./create-and-link-workflow";
import useProjectQuery from "components/project-workflow-list/queries";

function ProjectWorkflows() {
  const { projectId } = useParams();

  const { data, isLoading, isSuccess, isError } = useProjectQuery(projectId);

  return (
    <div className="workflows">
      <Breadcrumbs />
      <div className="workflows__container">
        <H2 className="workflows__name">
          <EditableProjectNameInput value={data?.name} />
        </H2>

        <div className="workflows__header">
          <H3 className="workflows__header-title">
            <Trans>Project Workflows</Trans>
          </H3>

          <div className="workflows__header-action">
            {isLoading && <StateLoading />}
            {isSuccess && !data?.isDefault && (
              <>
                <LinkWorkflowButton projectId={projectId} />
                <CreateAndLinkWorkflow />
              </>
            )}
          </div>
        </div>

        {isError && (
          <ErrorMessage
            title={<Trans id="Error" />}
            description={<Trans id="Error fetch projects workflows" />}
          />
        )}

        {isSuccess && <ProjectWorkflowList />}
      </div>
    </div>
  );
}

export default React.memo(ProjectWorkflows);
