import Cookies from "js-cookie";
import keycloak from "./client";

const KeycloakCookies = {
  TOKEN: "KEYCLOAK_TOKEN", // access token
  REFRESH_TOKEN: "KEYCLOAK_REFRESH_TOKEN",
};

const ONE_MONTH = 30 * 24 * 60 * 60; // 1 Day = 24 Hrs = 24*60*60 = 86400.
const HALF_HOUR = 30 * 60; // DEFAULT KEYCLOAK TIMEOUT

const getExpire = (exp) => new Date(Date.now() + exp * 1000);

const secureOptions = { path: "/", secure: true, sameSite: "lax" };

const getRefreshOptions = () => ({
  ...secureOptions,
  expires: getExpire(ONE_MONTH),
});

const getAccessOptions = () => ({
  ...secureOptions,
  expires: getExpire(HALF_HOUR),
});

let refreshInterval = null;

export const clientCookies = {
  setTokens(kc) {
    if (!kc || !kc.token || !kc.refreshToken) return;

    const accessOptions = getAccessOptions();

    Cookies.set(KeycloakCookies.TOKEN, kc.token, accessOptions);
    Cookies.set(
      KeycloakCookies.REFRESH_TOKEN,
      kc.refreshToken,
      getRefreshOptions(),
    );

    if (!refreshInterval) {
      refreshInterval = setInterval(() => {
        keycloak.updateToken(-1);
        // Update token's before expire
      }, (HALF_HOUR - 10) * 1000);
    }
  },

  getTokens() {
    return {
      token: Cookies.get(KeycloakCookies.TOKEN), // same name as in keycloak.js
      refreshToken: Cookies.get[KeycloakCookies.REFRESH_TOKEN], // same name as in keycloak.js
    };
  },

  clear() {
    Cookies.remove(KeycloakCookies.TOKEN, secureOptions);
    Cookies.remove(KeycloakCookies.REFRESH_TOKEN, secureOptions);
  },
};
