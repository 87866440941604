export const COLOR_BY_STATE = [
  "match",
  ["get", "status"],
  "UNPROCESSED",
  "#8A9BA8",
  "OK",
  "#0F9960",
  "IN_PROGRESS",
  "#2965CC",
  "FAILED",
  "#D13913",
  "#088",
];
export const AoiSourcesIds = { AOI_GEOJSON: "wm-aoi-geojson" };
export const AoiLayerIds = {
  AOI_POLYGONS: "wm-aoi-polygons",
  AOI_POINTS: "wm-aoi-points",
};

export const AoiSources = {
  AOI_GEOJSON: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] },
  },
};

export const AoiLayers = {
  AOI_POLYGONS: {
    id: AoiLayerIds.AOI_POLYGONS,
    type: "fill",
    source: AoiSourcesIds.AOI_GEOJSON,
    filter: ["==", "$type", "Polygon"],
    paint: {
      "fill-color": COLOR_BY_STATE,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        1,
        14,
        0.8,
        16,
        0.1,
      ],
    },
  },
  AOI_POINTS: {
    id: AoiLayerIds.AOI_POINTS,
    type: "circle",
    source: AoiSourcesIds.AOI_GEOJSON,
    filter: ["==", "$type", "Point"],
    layout: {
      "circle-sort-key": ["/", 1, ["get", "radius"]],
    },
    paint: {
      "circle-opacity": 0.8,
      "circle-color": COLOR_BY_STATE,
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["*", 1.2, ["get", "radius"]],
        10,
        ["*", 1, ["get", "radius"]],
        20,
        ["*", 1, ["get", "radius"]],
      ],
    },
  },
};
