import { gql } from "@apollo/client";
import client from "graphql/client";

export const GET_PROCESSINGS_PAGED = gql`
  query getProcessingsPaged(
    $limit: Int!
    $offset: Int!
    $terms: String
    $dateFrom: Date
    $dateTo: Date
  ) {
    processingsPaged(
      filters: {
        offset: $offset
        limit: $limit
        terms: $terms
        dateFrom: $dateFrom
        dateTo: $dateTo
      }
    ) {
      count
      total
      results {
        id
        projectId
        name
        projectName
        email
        created
        updated
        description
        area
        progress {
          status
          percentCompleted
          completionDate
        }
        messages {
          message
        }
        workflowDef {
          name
        }
      }
    }
  }
`;

export const getProcessingsPaged = async (data) => {
  const result = await client.query({
    query: GET_PROCESSINGS_PAGED,
    variables: data,
    fetchPolicy: "no-cache",
  });
  return result?.data?.processingsPaged;
};
