import { gql } from "@apollo/client";

export const GET_DATA_PROVIDERS = gql`
  query getDataProviders {
    dataProviders {
      id
      name
      displayName
      urlTemplate
      previewUrl
      pricePerMp
      credentialsToken
      credentialsUsername
      credentialsPassword
      isDefault
    }
  }
`;

export const GET_DATA_PROVIDER = gql`
  query getDataProvider($id: [ID!]) {
    dataProviders(ids: $id) {
      id
      name
      displayName
      urlTemplate
      previewUrl
      pricePerMp
      credentialsUsername
      credentialsPassword
      credentialsToken
      isDefault
    }
  }
`;

// create data provider
export const CREATE_DATA_PROVIDER = gql`
  mutation createDataProvider($data: CreateDataProviderInput!) {
    createDataProvider(data: $data) {
      id
      name
      displayName
      urlTemplate
      previewUrl
      pricePerMp
      credentialsUsername
      credentialsPassword
      credentialsToken
      isDefault
    }
  }
`;

//  update data provider
export const UPDATE_DATA_PROVIDER = gql`
  mutation updateDataProvider($data: UpdateDataProviderInput!) {
    updateDataProvider(data: $data) {
      id
      name
      displayName
      urlTemplate
      previewUrl
      pricePerMp
      credentialsUsername
      credentialsPassword
      credentialsToken
      isDefault
    }
  }
`;


// delete 
export const DELETE_DATA_PROVIDER = gql`
  mutation deleteDataProvider($id: ID!) {
    deleteDataProvider(id: $id)
  }
`;

//link unlink
export const LINK_DATA_PROVIDER = gql`
mutation linkDataProvider($userId: ID!, $dataProviderId: ID!) {
  linkDataProvider(userId: $userId, dataProviderId: $dataProviderId)
}
`;

export const UNLINK_DATA_PROVIDER = gql`
  mutation unlinkDataProvider($userId: ID!, $dataProviderId: ID!) {
    unlinkDataProvider(userId: $userId, dataProviderId: $dataProviderId)
  }
`;

// users
export const GET_USERS_LINKED_TO_DATA_PROVIDER = gql`
query getDataProviderUsers($id: ID!) {
  dataProviderUsers(id: $id) {
    id
    email
  }
}
`;

