import React from "react";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak, { clientCookies } from "shared/keycloak";
import { Loading } from "components";
import { isProduction } from "../index";

const KeycloakProvider = ({ children }) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        enableLogging: true,
        ...clientCookies.getTokens(),
      }}
      onTokens={clientCookies.setTokens}
      LoadingComponent={<Loading className="loading-full" />}
      onEvent={(event, error) => {
        if (!isProduction) {
          console.log(`[KEYCLOAK]: event - ${event}; error -`, error);
        }
        // Dont fired
        if (event === "onTokenExpired") {
          keycloak.updateToken(-1);
        }
      }}
    >
      <IsLoggedIn>{children}</IsLoggedIn>
    </ReactKeycloakProvider>
  );
};

function IsLoggedIn({ children }) {
  if (!keycloak.authenticated) {
    window.location.replace(
      keycloak.createLoginUrl({ redirectUri: window.location.origin }),
    );

    return <Loading className="loading-full" />;
  }

  return <>{children}</>;
}

export default KeycloakProvider;
