const padTime = (time) => String(time).padStart(2, "0");

export const getProcessETA = (estimate)=> {
    const hours = Math.floor(estimate / 3600);
    const minutes = Math.floor((estimate % 3600) / 60);
    const seconds = estimate % 60;

    return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`
}


export const getProcessETAWithDate = (estimateInSeconds) => {
  const secondsPerMinute = 60;
  const secondsPerHour = 3600;
  const secondsPerDay = 86400;

  const days = Math.floor(estimateInSeconds / secondsPerDay);
  const remainingSeconds = estimateInSeconds % secondsPerDay;

  const hours = Math.floor(remainingSeconds / secondsPerHour);
  const remainingMinutes = Math.floor((remainingSeconds % secondsPerHour) / secondsPerMinute);

  const minutes = remainingMinutes;
  const seconds = remainingSeconds % secondsPerMinute;

  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + estimateInSeconds * 1000);

  const day = padTime(futureDate.getDate());
  const month = padTime(futureDate.getMonth() + 1); // Note: Month is zero-based
  const year = futureDate.getFullYear();

  const formattedTime = `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;

  return `${day}.${month}.${year}. ${formattedTime}` ;
};

