import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { DateRangeInput2 } from "@blueprintjs/datetime2";

import { Button, Divider } from "@blueprintjs/core";

import useDebounce from "hooks/use-debounce";
import usePrevious from "hooks/use-previous";

import { getProcessingsPaged } from "./queries";

import ProcessingsHeader from "./header";
import { Pagination } from "components";
import { DATE_FNS_FORMATS } from "shared/date/date";
import { DEFAULT_SHORTCUTS } from "shared/date/daterange-shortcuts";
import { IconNames } from "@blueprintjs/icons";
import { ProcessingsContent } from "./processings-content";
import { t } from "@lingui/macro";

const DEFAULT_PAGE_SIZE = 20;

function ProcessingsStats() {
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);

  const debouncedSearch = useDebounce(search);
  const previousSearch = usePrevious(debouncedSearch);

  const { data, status, isFetching } = useQuery({
    queryKey: [
      "processings-stats",
      {
        page,
        pageSize,
        search: debouncedSearch,
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      },
    ],
    queryFn: () => {
      const options = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        terms: debouncedSearch,
      };

      if (dateRange[0]) {
        Object.assign(options, { dateFrom: dateRange[0] });
      }

      if (dateRange[1]) {
        Object.assign(options, { dateTo: dateRange[1] });
      }

      if (debouncedSearch !== previousSearch) {
        setPage(1);
      }

      return getProcessingsPaged(options);
    },
    refetchInterval: 10_000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const handleChangeDateRange = (range) => {
    setDateRange(range);
    setPage(1);
  };

  const handleClearFilter = () => {
    setPage(1);
    setSearch("");
    setDateRange([null, null]);
  };

  const items = data?.results || [];

  return (
    <div className="processings-stats">
      <ProcessingsHeader search={search} setSearch={setSearch} />
      <div className="processings-stats__controls">
        <Pagination
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          isLoading={isFetching}
          limit={pageSize}
          offset={(page - 1) * pageSize}
          total={data?.total || 0}
          showSinglePage
        />
        <Divider />
        <DateRangeInput2
          className="processings-stats__controls__date-range"
          {...DATE_FNS_FORMATS.MMddyyyy}
          value={dateRange}
          onChange={handleChangeDateRange}
          shortcuts={DEFAULT_SHORTCUTS}
          closeOnSelection={false}
          highlightCurrentDay
          allowSingleDayRange
          // If enabled, it cause bug with shortcuts, changes dont correct update range in month view
          singleMonthOnly={false}
          startInputProps={{placeholder: t`Start date`}}
          endInputProps={{placeholder: t`End date`}}
        />
        <Button
          outlined
          disabled={dateRange.every((v) => v === null)}
          icon={IconNames.CROSS}
          onClick={() => handleChangeDateRange([null, null])}
        />
      </div>
      <ProcessingsContent
        hasFilters={search || page > 1 || dateRange.some(Boolean)}
        onClear={handleClearFilter}
        status={status}
        data={items}
      />
    </div>
  );
}

export default ProcessingsStats;
