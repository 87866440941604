import { EditableText, Intent, Spinner } from "@blueprintjs/core";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { getErrorToast, showToast } from "../../toaster";
import { useApolloClient } from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { UPDATE_PROJECT_NAME } from "../../shared/api/project";

const EditableProjectNameInput = ({ value }) => {
  const { projectId } = useParams();

  const [input, setInput] = useState(value);
  const [confirmed, setConfirmed] = useState(value);

  const client = useApolloClient();

  const changeStates = (value) => {
    setInput(value);
    setConfirmed(value);
  };

  const updateProjeectName = useMutation({
    mutationKey: ["updateProjectName", projectId],
    mutationFn: async ({ projectId, name }) => {
      const result = await client.mutate({
        mutation: UPDATE_PROJECT_NAME,
        variables: { projectId: projectId, name: name },
      });
      return result.data.updateProject.name;
    },
    onSuccess: (name) => {
      changeStates(name);
      showToast({
        message: t`Project name "${name}" updated`,
        intent: Intent.SUCCESS,
      });
    },
    onError: () => {
      changeStates(confirmed);
      showToast(getErrorToast(t`Error delete project name "${confirmed}"`));
    },
  });

  const isLoading = updateProjeectName?.isLoading;

  useEffect(() => {
    changeStates(value);
  }, [value]);

  const handleConfirmed = (newValue) => {
    const trimedValue = newValue.trim();

    if (trimedValue === confirmed || !trimedValue) {
      changeStates(confirmed);
      return;
    }

    updateProjeectName.mutate({
      projectId,
      name: trimedValue,
    });
  };

  return (
    <div className="editable">
      <EditableText
        minLines={1}
        maxLines={1}
        value={input}
        placeholder={confirmed}
        disabled={isLoading}
        onChange={setInput}
        onConfirm={handleConfirmed}
      />
      {isLoading && <Spinner size={32} intent={Intent.PRIMARY} />}
    </div>
  );
};

export default EditableProjectNameInput;
