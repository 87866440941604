import { gql } from "@apollo/client";

export const UPDATE_PROJECT_NAME = gql`
 mutation updateProject($projectId: ID!, $name: String!) {
    updateProject(data: { id: $projectId, name: $name }) {
      id
      name
     }
   }
`;


