import { clone } from "./common";

function createShortcut(label, dateRange) {
  return { dateRange, label };
}

export function createDefaultShortcuts() {
  const today = new Date();

  const makeDate = (action) => {
    const returnVal = clone(today);
    action(returnVal);
    returnVal.setDate(returnVal.getDate() + 1);
    return returnVal;
  };

  const oneWeekAgo = makeDate((d) => d.setDate(d.getDate() - 7));
  const oneMonthAgo = makeDate((d) => d.setMonth(d.getMonth() - 1));
  const threeMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 3));
  const sixMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 6));
  const oneYearAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 1));

  return [
    // Nothing selected - All
    createShortcut("All time", [null, null]),
    createShortcut("Today", [today, today]),
    createShortcut("1 week ago", [oneWeekAgo, today]),
    createShortcut("1 month ago", [oneMonthAgo, today]),
    createShortcut("3 months ago", [threeMonthsAgo, today]),
    createShortcut("6 months ago", [sixMonthsAgo, today]),
    createShortcut("1 year ago", [oneYearAgo, today]),
  ];
}

export const DEFAULT_SHORTCUTS = createDefaultShortcuts();
