import { Icon, Tag } from "@blueprintjs/core";
import { Trans } from "@lingui/macro";
import Table from "components/table";
import { useMemo } from "react";
import { i18n } from "providers/__mocks__/LanguageProvider";
import { DEFAULT_DATE_FORMAT } from "constants/common";
import StatusTag from "components/status-tag";
import AoiMessagesDialog from "containers/aoi-list/aoi-messages-dialog";

export const ProcessingsTable = ({ data }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: (
          <>
            <Trans id="Workflow" />
          </>
        ),
        accessor: "workflowDef.name",
        Cell: ({ cell }) => (
          <Tag>
            <span style={{ marginRight: "5px" }}>
              <Icon icon="application" />
            </span>
            {cell.value}
          </Tag>
        ),
      },
      {
        Header: <Trans id="Name" />,
        id: "name",
        accessor: "name",
      },
      {
        Header: <Trans id="Project name" />,
        id: "projectName",
        accessor: "projectName",
      },
      {
        Header: <Trans id="Email" /> ,
        id: "email",
        accessor: "email",
      },
      {
        Header: <Trans id="Status" />,
        accessor: "progress.status",
        Cell: ({ cell }) => {
          const messages = cell.row.original?.messages ?? null;
          return (
            <div className="progress-status">
              <StatusTag statusCode={cell.value} />
              <AoiMessagesDialog messages={messages} />
            </div>
          );
        },
      },
      {
        Header: <Trans id="Created" />,
        id: "created",
        accessor: "created",
        Cell: ({ cell }) => {
          const date = cell.value.slice(0, cell.value.length - 5);
          return i18n.date(date, DEFAULT_DATE_FORMAT);
        },
      },
      {
        Header: <Trans id="completionDate" />,
        id: "completionDate",
        accessor: "progress.completionDate",
        Cell: ({ cell }) => {
          if (!cell.value) {
            return "-";
          }

          const date = cell.value.slice(0, cell.value.length - 5);
          return i18n.date(date, DEFAULT_DATE_FORMAT);
        },
      },
      {
        Header: <Trans id="Progress" />,
        accessor: "progress.percentCompleted",
        Cell: ({ cell }) => `${cell.value} %`,
      },
    ];
  }, []);

  return <Table striped data={data} columns={columns} />;
};

export default ProcessingsTable;
