import React from "react";
import classnames from "classnames";
import { t, Trans } from "@lingui/macro";

import { useForm } from "react-hook-form";
import { Button, Intent, Callout } from "@blueprintjs/core";
import { Dialog, Classes } from "@blueprintjs/core";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { showToast, getSuccessToast, getErrorToast } from "toaster";
import { useInputParams } from "hooks/use-input-params";
import { PROJECT_PROCESSING } from "constants/routes";
import { useGoTo } from "hooks/use-go-to";
import { useTheme } from "hooks/use-theme";
import { setTestId } from "test-utils/set-testid";
import { useDisclosure } from "hooks/use-disclosure";
import { gql, useApolloClient } from "@apollo/client";
import { CheckboxGroup } from "../checkbox-group";

export const fieldsConfig = {
  name: {
    maxLength: 40,
    required: true,
  },
  desc: {
    maxLength: 120,
  },
  dataProviderId: {
    required: true,
  },
};

export const CREATE_PROCESSING = gql`
  mutation createProcessing($data: CreateProcessingInput!) {
    createProcessing(data: $data) {
      id
      name
    }
  }
`;

export const GET_DATA_PROVIDERS = gql`
  query getDataProviders {
    dataProviders {
      id
      displayName
      name
      urlTemplate
    }
  }
`;

const getDataProviders = (client) => async (variables) => {
  const result = await client.query({
    query: GET_DATA_PROVIDERS,
    fetchPolicy: "no-cache",
    variables: variables,
  });

  return result?.data?.dataProviders;
};

const createProcessing = (client) => async (variables) => {
  const result = await client.mutate({
    mutation: CREATE_PROCESSING,
    fetchPolicy: "no-cache",
    variables: { data: variables },
  });
  return result?.data?.createProcessing;
};

function CreateProcessingDialog({
  children,
  workflowName,
  workflowDesc,
  workflowDefId,
  defaultIsOpen = false,
  blocks,
}) {
  const queryClient = useQueryClient();

  const { projectId } = useParams();
  const goToProcessing = useGoTo(PROJECT_PROCESSING);

  const dialogStatus = useDisclosure(defaultIsOpen);

  const { themeClassName } = useTheme();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const nameParams = useInputParams(errors.name, {
    required: t`This field is required`,
    maxLength: t`The name field may not be greater than ${fieldsConfig.name.maxLength} characters`,
  });

  const descParams = useInputParams(errors.desc, {
    maxLength: t`The description field may not be greater than ${fieldsConfig.desc.maxLength} characters`,
  });

  const dataProvidersQuery = useQuery({
    queryKey: ["data-providers"],
    queryFn: () => getDataProviders(client)(),
    select: (providers) => {
      return providers.filter((provider) => provider.urlTemplate !== null);
    },
    initialData: [],
  });

  const client = useApolloClient();
  const mutation = useMutation(createProcessing(client), {
    onSuccess: ({ id: processingId, name }) => {
      queryClient.invalidateQueries(["processings", projectId]);
      showToast(getSuccessToast(t`Processing ${name} successfully created`));
      dialogStatus.onClose();
      goToProcessing({ projectId, processingId });
    },
    onError: () => showToast(getErrorToast(t`Error creating processing`)),
  });

  const onSubmit = (formData) => {
    const { name, desc, processingBlocks, dataProviderId } = formData;

    const mutatedBlocks = blocks.map((item) => ({
      name: item.name,
      enabled: processingBlocks?.some((block) => item.name === block),
    }));

    const payload = {
      projectId,
      workflowDefId,
      name,
      description: desc,
      blocks: mutatedBlocks,
    };

    if (dataProviderId) Object.assign(payload, { dataProviderId });

    mutation.mutate(payload);
  };

  const registerField = (elName, options) => {
    const { onChange, onBlur, name, ref } = register(elName, options);

    return { onChange, onBlur, name, inputRef: ref };
  };

  return (
    <>
      <Dialog
        className={classnames(themeClassName, "create-processing-dialog")}
        title={<Trans>Create processing</Trans>}
        isOpen={dialogStatus.isOpen}
        onClose={dialogStatus.onClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <Callout className="workflow-info" title={workflowName}>
              {workflowDesc}
            </Callout>
            <FormGroup
              label={<Trans>Name</Trans>}
              labelFor="name"
              helperText={<Trans id={nameParams.helper} />}
              intent={nameParams.intent}
            >
              <InputGroup
                large
                autoFocus
                id="name"
                type="text"
                intent={nameParams.intent}
                disabled={mutation.isLoading}
                {...registerField("name", fieldsConfig.name)}
              />
            </FormGroup>
            <FormGroup
              label={<Trans>Description</Trans>}
              labelFor="desc"
              labelInfo={<Trans>(optional)</Trans>}
              helperText={<Trans id={descParams.helper} />}
              intent={descParams.intent}
            >
              <InputGroup
                large
                id="desc"
                type="text"
                intent={descParams.intent}
                disabled={mutation.isLoading}
                {...registerField("desc", fieldsConfig.desc)}
              />
            </FormGroup>
            <FormGroup
              label={<Trans>Data Provider</Trans>}
              labelFor="workflowId"
              helperText={<Trans id={descParams.helper} />}
              intent={descParams.intent}
            >
              <div className="bp4-html-select" style={{ width: "100%" }}>
                <select
                  id="workflowId"
                  disabled={dataProvidersQuery.isLoading}
                  {...register("dataProviderId", fieldsConfig.dataProviderId,)}
                >
                  {dataProvidersQuery.data &&
                    dataProvidersQuery.data.map(({ displayName, name, id }) => {
                      return (
                        <option key={id} value={id}>
                          {displayName || name}
                        </option>
                      );
                    })}
                </select>
                <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
              </div>
            </FormGroup>

            {blocks.length > 0 && (
              <CheckboxGroup
                name="processingBlocks"
                options={blocks}
                control={control}
                label={<Trans>Options</Trans>}
              />
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                elementRef={setTestId`cancel-create-processing`}
                intent={Intent.NONE}
                text={<Trans>Cancel</Trans>}
                onClick={dialogStatus.onClose}
                disabled={mutation.isLoading}
              />
              <Button
                elementRef={setTestId`submit-create-processing`}
                type="submit"
                intent={Intent.PRIMARY}
                text={<Trans id="Save" />}
                loading={mutation.isLoading}
              />
            </div>
          </div>
        </form>
      </Dialog>
      {typeof children === "function"
        ? children({
            showDialog: dialogStatus.onOpen,
            hideDialog: dialogStatus.onClose,
          })
        : children}
    </>
  );
}

export default React.memo(CreateProcessingDialog);
