import React from "react";
import {
  Popover,
  PopoverInteractionKind,
  Position,
  Button,
  Menu,
  MenuItem,
  MenuDivider,
  Intent,
} from "@blueprintjs/core";
import { t, Trans } from "@lingui/macro";
import { StyleNames, Styles, STYLE_BASE_URL } from "constants/mapbox";
import { MapAPIContext } from "pages/processing/map-api-context";
import { setTestId } from "test-utils/set-testid";

import { getPersistLayers } from "./get-persist-layers";

const overlays = [
  { styleName: StyleNames.DARK, icon: "moon", text: t`Dark` },
  { styleName: StyleNames.LIGHT, icon: "flash", text: t`Light` },
  { styleName: StyleNames.SATELLITE, icon: "satellite", text: t`Satellite` },
  { styleName: StyleNames.STREETS, icon: "office", text: t`Streets` },
  { styleName: StyleNames.OUTDOORS, icon: "mountain", text: t`Outdoors` },
];

function setMapStyle(mapAPI, style) {
  const styleObject = mapAPI.getStyle();
  const { sources, layers } = getPersistLayers({ prefix: "wm-", styleObject });
  mapAPI.setStyle(`${STYLE_BASE_URL}${Styles[style]}`);

  mapAPI.once("styledata", () => {
    for (const [id, data] of sources) mapAPI.addSource(id, data);
    for (const layer of layers) mapAPI.addLayer(layer);
  });
}

function BasemapSwitcher({ defaultStyle = StyleNames.DARK }) {
  const [style, setStyle] = React.useState(defaultStyle);
  const mapAPI = React.useContext(MapAPIContext);

  const updateStyle = (styleName) => {
    if (styleName !== style) {
      setStyle(styleName);
      setMapStyle(mapAPI, styleName);
    }
  };

  return (
    <div>
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM_LEFT}
      >
        <Button elementRef={setTestId`layers-menu`} icon="layers" large />
        <Menu>
          <MenuDivider title={<Trans>Base overlay</Trans>} />
          {overlays.map(({ styleName, text, icon }, index) => (
            <MenuItem
              key={index}
              icon={icon}
              text={<Trans id={text} />}
              intent={styleName === style ? Intent.PRIMARY : Intent.NONE}
              onClick={() => updateStyle(styleName)}
            />
          ))}
        </Menu>
      </Popover>
    </div>
  );
}

export default React.memo(BasemapSwitcher);
