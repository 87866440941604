/* globals mapboxgl */
import { useEffect } from "react";

export const defaultMapOptions = {
  style: "mapbox://styles/mapbox/dark-v10",
  center: [0, 0],
  zoom: 0,
};

export function useMapboxGl(nodeRef, setMapAPI, options) {
  useEffect(() => {
    const container = nodeRef.current;
    if (!container) return;
    const mapOptions = { container, ...defaultMapOptions, ...options };
    const mapboxGlMap = new mapboxgl.Map(mapOptions);
    mapboxGlMap.once("load", () => setMapAPI(mapboxGlMap));
    window.mapboxMap = mapboxGlMap;
    return () => {
      mapboxGlMap.remove();
      delete window.mapboxMap;
    };
  }, [nodeRef]); // eslint-disable-line react-hooks/exhaustive-deps
}
