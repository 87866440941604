import React, { useState, useCallback } from "react";
import { ResizeSensor } from "@blueprintjs/core";

import { MapAPIContext } from "./map-api-context";
import {
  ProcessingMap,
  ProcessingSidebar,
  UploadAoiDialog,
  Breadcrumbs,
} from "containers";

function Processing() {
  const [mapAPI, setMapAPI] = useState(null);

  const handleResize = useCallback(() => {
    if (mapAPI) mapAPI.resize();
  }, [mapAPI]);

  const [showUploadAoiDialog, setShowUploadAoiDialog] = useState(false);

  return (
    <MapAPIContext.Provider value={mapAPI}>
      <div className="processing-page">
        <ResizeSensor onResize={handleResize}>
          <ProcessingMap ref={setMapAPI} mapAPI={mapAPI} />
        </ResizeSensor>
        <Breadcrumbs className="processing-breadcrumbs" />
        <ProcessingSidebar
          mapAPI={mapAPI}
          openUploadAoiDialog={() => setShowUploadAoiDialog(true)}
        />
        <UploadAoiDialog
          mapAPI={mapAPI}
          isOpen={showUploadAoiDialog}
          openDialog={() => setShowUploadAoiDialog(true)}
          handleClose={() => setShowUploadAoiDialog(false)}
        />
      </div>
    </MapAPIContext.Provider>
  );
}

export default React.memo(Processing);
