import React, { useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import classnames from "classnames";
import { HTMLTable, Icon, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

function getSortinIcon(column, sortBy) {
  if (column.isSorted)
    if (column.isSortedDesc) return IconNames.CARET_DOWN;
    else return IconNames.CARET_UP;
  if (sortBy.length === 0) return IconNames.DOUBLE_CARET_VERTICAL;
}
export function HeaderColumn({ column, sortBy, filter }) {
  const iconName = getSortinIcon(column, sortBy);
  const iconClasses = classnames("sorting-icon", {
    "sorting-icon--selected": column.isSorted,
  });
  const headerCellProps = column.getHeaderProps(column.getSortByToggleProps());

  const style = column.disableSortBy ? { pointerEvents: "none" } : {};
  return (
    <th className="header" {...headerCellProps} style={style}>
      <div className="header-cell">
        {column.render("Header")}
        <span className={iconClasses}>
          {column.canSort && <Icon icon={iconName} />}
        </span>
      </div>
    </th>
  );
}

function Table({
  data,
  columns,
  className,
  bodyClassName,
  headingClassName,
  style,
  condensed = false,
  striped = true,
  bordered = false,
  interactive = false,
  onRowClick = () => {},
  indexColumn = false,
  disableSortByIndex = true,
  indexHeader = "#",
  // loading,
  // loadingRowsCount = 5,
}) {
  const columnsConfig = useMemo(
    () =>
      indexColumn
        ? [
            {
              id: "data-table-row-index",
              accessor: "index",
              Header: indexHeader,
              defaultCanSort: !disableSortByIndex,
              Cell: ({ row }) => row.index + 1,
              disableSortBy: disableSortByIndex,
            },
            ...columns,
          ]
        : columns,
    [columns, indexHeader, indexColumn, disableSortByIndex],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns: columnsConfig,
      data,
    },
    useSortBy,
  );

  return (
    <HTMLTable
      {...getTableProps()}
      condensed={condensed}
      interactive={interactive}
      striped={striped}
      // bordered={bordered}
      className={classnames("table", Classes.ELEVATION_0, className)}
      style={style}
    >
      <thead className={headingClassName}>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <HeaderColumn key={i} sortBy={state.sortBy} column={column} />
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        className={classnames("table__body", bodyClassName)}
        {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              key={i}
              {...row.getRowProps()}
              onClick={() => onRowClick(row.original)}
            >
              {row.cells.map((cell, i) => {
                return (
                  <td key={i} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}

export default React.memo(Table);
